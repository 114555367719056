import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const FamilieUndKinderZitate = () => (
    <InnerPage title="Zitate – Familie und Kinder" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Familie und Kinder"
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Familie und Kinder',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="‘Abdu’l-Bahá, Einheit der Familie">
                    Bahá’í-Ehe bedeutet die Bindung zweier Partner aneinander
                    und ihre gegenseitige Zuneigung im Denken und Fühlen. Sie
                    müssen sich jedoch mit größter Sorgfalt bemühen, mit der
                    Wesensart des anderen gründlich vertraut zu werden, so daß
                    der feste Bund zwischen ihnen eine ewige Bindung werde. Ihr
                    Bestreben muß sein, liebevolle Gefährten und für immer und
                    ewig in Einklang miteinander zu sein..
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Einheit der Familie">
                    O ihr, die ihr beide an Gott glaubt! Der Herr, einzig ist
                    Er, hat Mann und Frau erschaffen, damit sie in engster
                    Gemeinschaft miteinander leben und wie eine einzige Seele
                    seien. Sie sind zwei Gefährten, zwei nahe Freunde, die auf
                    ihr gegenseitiges Wohl bedacht sein sollten. Wenn sie so
                    leben, werden sie diese Welt vollkommen zufrieden,
                    glückselig und mit heiterem Gemüt durchschreiten und im
                    Königreich des Himmels zum Ziel göttlicher Gnade und Gunst
                    werden…Seid daher bemüht, mit Leib und Seele wie zwei Tauben
                    in einem Nest miteinander zu wohnen, denn dies bedeutet
                    Segen in beiden Welten.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Einheit der Familie">
                    Beachte, wie leicht sich die Angelegenheiten einer Familie
                    regeln lassen, wenn Einheit herrscht, welche Fortschritte
                    die Familienmitglieder dann machen, wie erfolgreich sie in
                    der Welt sind. Ihre Beziehungen sind geordnet, sie erfreuen
                    sich behaglicher Ruhe. Sie sind ohne Sorge, ihre Stellung
                    ist gesichert, sie werden von allen beneidet. Mit jedem Tag
                    festigt eine solche Familie ihre Stellung und mehrt ihre
                    dauernde Ehre.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Einheit der Familie">
                    Wisset, o ihr liebenden Mütter: In den Augen Gottes ist der
                    beste Weg, Ihn zu verherrlichen, die Erziehung der Kinder
                    und ihre Bildung in allen Vollkommenheiten der Menschheit.
                    Keine edlere Tat ist denkbar.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Einheit der Familie">
                    Wenn du deinen Eltern solche Zuneigung und Achtung bezeigst,
                    daß sie zufrieden sind, bin auch Ich zufrieden; denn Eltern
                    müssen hoch geachtet werden, und es ist wichtig, daß sie
                    zufrieden sind..
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Gebete">
                    O Herr! In dieser größten Sendung nimmst Du die Fürbitte der
                    Kinder für ihre Eltern an. Dies ist eine der besonderen,
                    unendlichen Gnadengaben dieser Sendung. Nimm deshalb, o Du
                    gütiger Herr, die Bitte Deines Dieners an der Schwelle
                    Deiner Einzigkeit an und lasse seinen Vater versinken im
                    Meere Deiner Gnade. Denn dieser Sohn hat sich erhoben, Dir
                    zu dienen, und müht sich unentwegt auf dem Pfade Deiner
                    Liebe. Wahrlich, Du bist der Gebende, der Verzeihende und
                    der Gütige.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Briefe und Botschaften">
                    Die Erziehung und Ausbildung der Kinder gehört zu den
                    verdienstvollsten Taten der Menschheit. Sie zieht die Gnade
                    und den Segen des Allbarmherzigen auf sich; denn Erziehung
                    ist die unentbehrliche Grundlage jeder herausragenden
                    menschlichen Leistung und erlaubt dem Menschen, sich seinen
                    Weg zu den Höhen immerwährender Herrlichkeit zu bahnen.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Die erste und dringlichste Notwendigkeit ist die Förderung
                    der Erziehung. Man kann sich nicht denken, daß ein Volk zu
                    Wohlstand und Erfolg kommt, ohne daß diese ausschlaggebende,
                    grundlegende Frage vorangetrieben wird. Die Hauptursache für
                    den Niedergang und Verfall der Völker ist Unwissenheit.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Beantwortete Fragen">
                    Die menschliche Erziehung bedeutet Kultur und Fortschritt,
                    nämlich Regierung, Verwaltung, Wohlfahrtseinrichtungen,
                    Verkehr, Handel und Gewerbe, Künste, Natur- und
                    Geisteswissenschaften, große Erfindungen und Entdeckungen
                    physikalischer Gesetze. Diese Äußerungen des Menschengeistes
                    machen den Unterschied zur Tierwelt deutlich.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ziele der Kindererziehung">
                    Wir verordnen für alle Menschen das, was zur Erhöhung des
                    Wortes Gottes unter Seinen Dienern führt und gleicherweise
                    zum FOrtschritt der Welt des Seins und zur Erhebung der
                    Seelen. Der beste Weg zu diesem Ziel ist die Erziehung des
                    Kindes.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FamilieUndKinderZitate;
